
.sidebar {
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  max-height: 100%;
  overflow-y: auto;
  transition: max-width 0.3s;
  display: flex;
  width: 256px;
}

.d-md-block {
  display: flex !important;
}

.bg-primary {
  background-color: #34485e !important;
  transition: ease all 0.3s;
  cursor: pointer;
  margin-bottom: 1px;
}
.bg-primary:hover{
  background-color:#0f4475 !important;
}
.bg-primary a{
  padding: 22px;
  color:#fff;
  display: block;
}
.bg-primary:hover a{
  color:#fff !important;
}
.bg-primary a.active{
  position: relative;
  background: transparent !important;
  color:#fff;
}
.bg-primary a.active:before{
  content:"";
  position:absolute;
  top:0px;
  left:0px;
  width:3px;
  height:100%;
  background: #5fb0e7;
}
.bg-primary a.active:after{
  content:"";
  position:absolute;
  top:0px;
  right:0px;
  width:60%;
  height:100%;
  background: linear-gradient(to right, rgba(0,0,0,0) ,rgba(0,0,0,0.5) );
}
.simplebar-wrapper {
  height: 100%;
  width: 256px;
  flex-direction: column;
}
