.ant-layout-footer {
  color: rgba(0, 0, 0, 0.85);
  height: 35px;
  position: fixed;
  width: 100%;
  bottom: 0px;
  max-width: 1660px;
  padding: 5px 30px;
  background: #fff;
  text-align: right;
}

p {
  font-family: verdana;
  font-size: 15px;
  color: #989898;
}