.nform-container {
  .form-actions {
    text-align: right;
  }
}


.dtable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.dtable td, .dtable th {
  border: 1px solid #ddd;
  padding: 8px;
}

.dtable tr:nth-child(even){background-color: #f2f2f2;}

.dtable tr:hover {background-color: #ddd;}

.dtable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #242a34;
  color: white;
}